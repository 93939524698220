import $ from 'jquery';

class Applause {
    constructor() {
        this.config = {
            selector: '.js-add-applause'
        };

        this.selector = $(this.config.selector);

        this.selector.click( function() {
            this.url = $(this).data('ajax-url');
            this.postID = $(this).data('post-id');

            $.ajax({
                url: this.url + '/wp/wp-admin/admin-ajax.php',
                data: {
                    action: 'set_applause',
                    postID: this.postID
                },
                type: 'post',
                success: (response) => {
                    $('.applause__count').each( function() {
                       $(this).html(response);
                    });
                }
            })
        });

    }

}

export default Applause;