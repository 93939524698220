class SearchDiv {
    constructor() {
        this.config = {
            selector: '.js-button-search',
            target: '.search-container',
            form: '.form-search',
            close: '.js-close-search'
        };

        this.selector = document.querySelector(this.config.selector);
        this.target = document.querySelector(this.config.target);
        this.form = document.querySelector(this.config.form);
        this.close = document.querySelector(this.config.close);

        this.selector.addEventListener('click', () => {
           this.target.classList.add('is-open');
        });

        this.close.addEventListener('click', () => {
            this.target.classList.remove('is-open');
        });

    }

}

export default SearchDiv;