import $ from 'jquery';

class ShareToggle {
    constructor() {
        this.config = {
            selector: '.js-toggle-share',
            target: '.share-buttons'
        };

        const $el = $(this.config.selector);

        $el.click( () => {
            $(this.config.target).slideToggle();
            return false;
        });

        $('body').click( () => {
            $(this.config.target).slideUp();
        });

    }

}

export default ShareToggle;