

class Gallery {

    constructor() {
        this.config = {
            selector: '.js-open-gallery'
        };

            require([
                '../../../node_modules/photoswipe/dist/photoswipe.js',
                '../../../node_modules/photoswipe/dist/photoswipe-ui-default.js'
            ], ( PhotoSwipe, PhotoSwipeUI_Default ) => {

                const pswpElement = document.querySelectorAll('.pswp')[0];
                const openGallery = document.querySelector(this.config.selector);

                if (window.galleryImages) {
                    window.galleryImages.forEach(el => {
                        el.src = el.url;
                        el.w = el.width;
                        el.h = el.height;
                    });

                    const options = {
                        index: 0
                    };

                    if (openGallery) {
                        openGallery.addEventListener('click', () => {
                            const gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, window.galleryImages, options);
                            gallery.init();
                        });
                    }
                }

        });

    }
}

export default Gallery;