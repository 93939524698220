import Config from 'Config';


import Router from '../modules/Router';

import Header from '../components/Header'
import ShareToggle from '../components/ShareToggle'
import Gallery from '../components/Gallery'
import Slideshow from '../components/Slideshow'
import BannerCarousel from "../components/BannerCarousel";
import AbbonamentiCarousel from "../components/AbbonamentiCarousel";
import ShowFilters from '../components/ShowFilters';
import FixedDiv from '../components/FixedDiv';
import SearchDiv from '../components/SearchDiv';
import Applause from '../components/Applause';

class Application {
  constructor() {
    this.config = Config;

    new Router({
      config: this.config
    });

    new Header();
    new ShareToggle();
    new Gallery();
    new Slideshow();
    new BannerCarousel();
    new AbbonamentiCarousel();
    new ShowFilters();
    new FixedDiv();
    new SearchDiv();
    new Applause();
  }
}

export default Application;
