import $ from 'jquery';
import 'slick-carousel';

class BannerCarousel {

    constructor() {
        this.config = {
            selector: '.js-abbonamenti-carousel'
        }

        this.$el = $(this.config.selector);
        this.hasCarousel = false;

        this.enableCarousel();
    }

    checkCarousel() {

        if (window.innerWidth < 1280) {
            if (!this.hasCarousel) {
                this.enableCarousel();
                this.hasCarousel = true;
            }
        } else {
            if (this.hasCarousel) {
                this.$el.slick('unslick');
                this.hasCarousel = false;
            }
        }

    }


    enableCarousel() {
        this.$el.slick({
            arrows: false,
            mobileFirst: true,
            slidesToShow: 1,
            responsive: [
                {
                    breakpoint: 599,
                    settings: {
                        slidesToShow: 2
                    }
                }
            ]
        });
    }
}

export default BannerCarousel;
