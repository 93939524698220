import $ from 'jquery';
import 'slick-carousel';

class Slideshow {

    constructor() {
        this.config = {
            selector: '.js-slideshow'
        }

        const $el = $(this.config.selector);

        $el.slick({
            arrows: false,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        arrows: true,
                        infinite: false
                    }
                }
            ]
        });
    }
}

export default Slideshow;