import $ from 'jquery';

class FixedDiv {
    constructor() {
        this.config = {
            selector: '.js-fixed-div',
            target: '.js-target-share',
            footer: '.post__footer'
        }

        const $el = $(this.config.selector);
        let $postFooter = 0;
        if ($(this.config.footer).length > 0) {
            $postFooter = $(this.config.footer).offset().top;
        }
        const $heightDiv = $el.height();

        if ($el.length > 0) {

            $(window).scroll( () => {
                const $scrollTop     = $(window).scrollTop();
                const $elementOffset = $(this.config.target).offset().top;
                const $distance      = ($elementOffset - $scrollTop);
                const distanceFooter = $postFooter - $heightDiv - 90;

                if ($distance <= 90) {
                    $el.css('display','block');

                    if ($scrollTop >= distanceFooter) {
                        $el.css('display','none');
                    }
                } else {
                    $el.css('display','none');
                }


            });

        }
    }

}

export default FixedDiv;
