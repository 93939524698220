import { Power2, TimelineMax } from 'gsap';

class Header {
    constructor() {
        this.config = {
            header: '.header',
            openMenu: '.js-open-menu',
            closeMenu: '.js-close-menu',
            mainMenu: '.main-menu',
            scrollvalue: '150'
        }

        this.header = document.querySelector(this.config.header);
        this.openMenu = document.querySelector(this.config.openMenu);
        this.closeMenu = document.querySelector(this.config.closeMenu);
        this.mainMenu = document.querySelector(this.config.mainMenu);
        this.circle = document.querySelector('.zoom-circle');
        this.animation = new TimelineMax();
        let scrollPos = window.scrollY;

        this.openMenu.addEventListener('click', () => {

            this.animation
                .to(this.circle, 0, {
                    zIndex: 9,
                    ease: Power2.easeInOut
                })
                .call( () => {
                    this.circle.classList.add('make-zoom');
                })
                .to(this.mainMenu, 0.2, {
                    display: 'block',
                    ease: Power2.easeInOut
                })
                .to(this.mainMenu, 0.2, {
                    opacity: 1,
                    ease: Power2.easeInOut
                })
        });

        this.closeMenu.addEventListener('click', () => {
            this.animation
                .to(this.mainMenu, 0.2, {
                    opacity: 0,
                    ease: Power2.easeInOut
                })
                .call( () => {
                    this.circle.classList.remove('make-zoom');
                })
                .to(this.mainMenu, 0.2, {
                    display: 'none',
                    ease: Power2.easeInOut
                })
                .to(this.circle, 0.2, {
                    zIndex: 0,
                    ease: Power2.easeInOut
                })
        });

        this.reducedHeaderOnScroll(scrollPos);

        window.addEventListener('scroll', () => {
            scrollPos = window.scrollY;
            this.reducedHeaderOnScroll(scrollPos);
        });
    }

    reducedHeaderOnScroll(scrollPos) {
        if (scrollPos >= this.config.scrollvalue) {
            this.header.classList.remove('header--big');
        } else {
            this.header.classList.add('header--big');
        }

    }

}

export default Header;