class ShowFilters {
    constructor() {
        this.config = {
            selector: '.js-show-filters',
            list: '.list-categories',
            link: '.js-cat-link',
            headerCategories: '.header__categories'
        };

        this.selector = document.querySelector(this.config.selector);
        this.list = document.querySelector(this.config.list);
        this.links = document.querySelectorAll(this.config.link);
        this.headerCat = document.querySelector(this.config.headerCategories);
        this.listWidth = 0;

        if (window.innerWidth < 768) {
            this.links.forEach(el => {
                this.listWidth += el.clientWidth;
            });

            this.list.style.width = Number(this.listWidth) + 1 + 'px';
        }

        this.selector.addEventListener('click', () => {
            this.selector.classList.toggle('is-active');
            this.list.classList.toggle('is-open');
            this.headerCat.classList.toggle('is-scrolled');
        })
    }

}

export default ShowFilters;